body {
  background-color: whitesmoke;
}

.logo {
  width: 112px !important;
  height: 28px !important;
}

.body-content {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main-content {
  flex: 1;
}

.table-row:hover {
  cursor: pointer;
}

.table-header:hover {
  cursor: pointer;
  background-color: whitesmoke;
}

.favorite-button:hover {
  cursor: pointer;
}

.about-profile-photo {
  margin-left: 0 !important;
  margin-right: 0 !important;
  height: 100px;
  width: 100px;
}

.about-profile-content {
  white-space: nowrap;
}
